import $ from 'jquery';

(function($) {

    $.fn.send_comment = function(options) {

        var settings = $.extend({
            // These are the defaults.
            id: null,
            jobId: null,
            userId: null,
            url: null,
            assetId: 0,
            populate: 1,
            callback: function() {}
        }, options);

        var comment_div = 'textarea[data-note="' + settings.id + '"]';
        var text = $(comment_div).redactor('source.getCode');

        // Set the parent id of the note to the note you are replying to
        // If not replying then just use the job id
        if ($('a.reply-comment[data-id="' + settings.id + '"]').attr('data-parent')) {
            var reply = 1;
            var noteId = settings.id;
        } else {
            var reply = 0;
            var noteId = settings.noteId;
        }

        if (text == "") {
            utilities.notify({
                message: 'Please add some text to your comment',
                status: 'info',
                timeout: 4000,
                pos: 'top-center'
            });
            return false;
        }

        var save_comment_modal = $.dialog({
            title: 'Saving comment',
            content: '<div id="saving-comment" class="align-center padding"><span class="spin white extra-large"></span><h4>Saving comment and notifying users</h4></div>',
            cancelButtonClass: 'btn-outline',
            cancelButton: "Cancel",
            columnClass: 'save-comment',
            theme: 'loading-comments',

            onOpen: function() {

                $.ajax({
                    type: 'POST',
                    url: settings.url.api,
                    data: {
                        action: 'comments',
                        type: 'save',
                        jobId: settings.jobId,
                        noteId: noteId,
                        userIds: settings.notify,
                        comment: text,
                        assetId: settings.assetId,
                        sendMail: 'y',
                        userId: settings.userId
                    }
                }).error(function(result) {

                    utilities.notify({
                        message: 'There was an error saving this comment. <br />Please try again',
                        status: 'warning',
                        timeout: 4000,
                        pos: 'top-center'
                    });

                }).success(function(result) {
                    console.log(result);
                    var data = {
                        comment: result.data[0],
                        url: settings.url
                    }

                  //save_comment_modal.close();

                    // If it's a reply to a comment it gets appended to the comment
                    // Otherwise we prepend it to all fo the comments
                    if(settings.populate) {

                       if (reply) {
                           $(".reply-comment-wrap[data-id='" + settings.id + "']").loadFromTemplate({
                               template: "sub-comment",
                               extension: '.html',
                               path: settings.url.app_resources + '/templates/common/',
                               data: data,
                               render_method: "prepend",

                               callback: function() {
                                   // Update comment count
                                   var count = $('#post-conversation-content .bubble').length;
                                   $('#comment-count').text(count);
                                   $(comment_div).redactor('source.setCode', '');
                                   $('#reply-comment-holder-' + settings.id).slideToggle();

                                   settings.callback.call(this);
                               }
                           });
                       } else {
                           $("#post-conversation-content").loadFromTemplate({
                               template: "comment",
                               extension: '.html',
                               path: settings.url.app_resources + '/templates/common/',
                               data: data,
                               render_method: "prepend",

                               callback: function() {
                                   var count = $('#post-conversation-content .bubble').length;
                                   $('#comment-count').text(count);
                                   $(comment_div).redactor('source.setCode', '');
                                   $('#sub-comment-loader-' + data.comment.noteId).hide();
                               }
                           });
                       }
                    } else {
                        settings.callback.call(this);
                    }
                });
            }
        });
    };

    $.fn.delete_comment = function(options) {

        var settings = $.extend({
            // These are the defaults.
            id: null,
            jobId: null,
            url: null,
            callback: function() {}
        }, options);

        $('section[data-id="' + settings.id + '"]').addClass('deleting');

        $.ajax({
            type: 'POST',
            url: settings.url.api,
            data: {
                action: 'comments',
                type: 'delete',
                jobId: settings.jobId,
                noteId: settings.id
            }
        }).error(function(result) {

            utilities.dismissNotifications();

            utilities.notify({
                message: 'There was an error deleting this comment. <br />Please try again',
                status: 'warning',
                timeout: 4000,
                pos: 'top-center'
            });
        }).success(function(result) {

            settings.callback.call(this);

        });
        return false;
    }


    $.fn.reply_comment = function(options) {

        var settings = $.extend({
            // These are the defaults.
            id: null,
            url: null,
            template: '',
            callback: function() {}
        }, options);



        if ($('section[data-id="' + settings.id + '"] .redactor-editor').length === 0) {
            var data = {
                id: settings.id
            }

            $(".new-comment-holder[data-id='" + settings.id + "']").loadFromTemplate({
                template: "reply-comment" + settings.template,
                extension: '.html',
                path: settings.url.app_resources + '/templates/common/',
                data: data,
                callback: function() {
                    $('section[data-id="' + settings.id + '"] .editor').redactor({
                        "minHeight": 100,
                        init: function() {
                             $('.re-html').click(function() {
                                var text = self.stripScripts($('#task-description').redactor("source.getCode"));
                                this.redactor("source.setCode", text);
                            });
                        }
                    });
                    $('#reply-comment-holder-' + settings.id).slideDown();
                    settings.callback.call(this);
                }
            });
        } else {
            $('#reply-comment-holder-' + settings.id).slideToggle();
            settings.callback.call(this);
        }
    }


    $.fn.get_child_notes = function(options) {

        var settings = $.extend({
            id: null,
            jobId: null,
            url: null,
            template: null,
            callback: function() {}
        }, options);

        // Get notes for each subnote
        var get_child_notes = settings.url.pulse + 'api.v2.php?action=comments&type=get&jobId=' + settings.jobId + '&noteId=' + settings.id;

        $.get(get_child_notes, function(data) {
            $('#sub-comment-loader-' + settings.id).hide();
            if (data.success) {
                data.data.reverse();
                $(".reply-comment-wrap[data-id='" + settings.id + "']").loadFromTemplate({
                    template: settings.template,
                    extension: '.html',
                    path: settings.url.app_resources + 'templates/common/',
                    render_method: 'prepend',
                    data: {
                      comments: data.data,
                      url: settings.url
                    },
                    callback: function() {
                        var count = $('#post-conversation-content .bubble').length;
                        $('#comment-count').text(count);
                        settings.callback.call(this);
                    }

                });
            }
        });
    }
})(jQuery);
